<template>
  <div>
    <Breadcrumb />

    <b-modal id="modal-add-workcenter" shadow right bg-variant="white" backdrop size="lg"
      :title="$t('WorkcenterList.Create')" centered no-close-on-backdrop>
      <add-workcenter @show-message="closeSideBarAdd" @refetch-data="getWorkcenters" :nameWorkcenters="nameWorkcenters"/>
    </b-modal>

    <b-modal id="modal-edit-workcenter" shadow right bg-variant="white" backdrop size="lg"
      :title="$t('WorkcenterList.EditTitle')" centered no-close-on-backdrop>
      <edit-workcenter :current-workcenter-id="currentWorkcenterId" @show-message="closeSideBarEdit"
        @refetch-data="getWorkcenters" :nameWorkcenters="nameWorkcenters" />
    </b-modal>

    <b-card id="card-workcenters">
      <div>
        <div class="d-flex align-items-center justify-content-start c-header">
          <b-form-group class="w-100">
            <div class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between">
              <span class="c-title"> {{ $t('WorkcenterList.Title') }} </span>
              <b-button variant="primary" class="btn-new-workcenter" v-b-modal.modal-add-workcenter>
                <span class="text-nowrap">{{ $t('WorkcenterList.Add') }}</span>
              </b-button>
            </div>
          </b-form-group>
        </div>

        <table-default v-if="rows.length > 0" :rows="rows" :fields="fields" :stickyHeader="false"
          class="table-workcenter">
          <template v-slot:cell(resources)="data">
            <div class="d-flex">
              <div class="add-ellipsis-w" :id="`tooltip-workcenter-resource-${data.index}`">
                <span>
                  {{ data.item.resources }}
                </span>
              </div>
            </div>
            <b-tooltip
              custom-class="tooltip-workcenter"
              triggers="hover blur"
              :target="`tooltip-workcenter-resource-${data.index}`"
              positioning="top"
              placement="top"
            >
              <div class="info-tooltip-w">
                <span>
                  {{ data.item.resources }}
                </span>
              </div>
            </b-tooltip>
          </template>

          <template v-slot:cell(users)="data">
            <div class="d-flex">
              <div class="add-ellipsis-w" :id="`tooltip-workcenter-users-${data.index}`">
                <span>
                  {{ data.item.users }}
                </span>
              </div>
            </div>
            <b-tooltip
              custom-class="tooltip-workcenter"
              triggers="hover blur"
              :target="`tooltip-workcenter-users-${data.index}`"
              positioning="top"
              placement="top"
            >
              <div class="info-tooltip-w">
                <span>
                  {{ data.item.users }}
                </span>
              </div>
            </b-tooltip>
          </template>

          <template v-slot:cell(action)="data">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret class="d-action-workcenters" :boundary="boundaryDrop">
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>
                <b-dropdown-item @click="setCurrentWorkcenter(data.item.id)" v-b-modal.modal-edit-workcenter
                  class="drop-workcenter">
                  <img src="@/assets/images/icons/edit-2.png" width="16px" alt="" class="dropdown-icon">
                  <span>{{ $t('WorkcenterList.Edit') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteWorkcenter(data.item.id)" class="drop-workcenter">
                  <img src="@/assets/images/icons/trash-light.png" width="16px" alt="" class="dropdown-icon">
                  <span>{{ $t('WorkcenterList.Delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </table-default>

        <div class="demo-spacing-0">
          <b-alert variant="primary" :show="(flagShowOverlay == false && rows.length == 0) ? true : false">
            <div class="alert-body">
              <span>Nenhum resultado encontrado.</span>
            </div>
          </b-alert>
        </div>
      </div>
    </b-card>
    <b-button hidden ref="closeAddStatus" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.sidebar-right-add>
    </b-button>
    <b-button hidden ref="closeEditStatus" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.sidebar-right-edit>
    </b-button>
  </div>
</template>

<script>
import Breadcrumb from '../../../layouts/components/Breadcrumb.vue'
import {
  BRow, BTooltip, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BAlert, BDropdown, BDropdownItem, BCard, BSidebar, VBToggle, BButton, BModal
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddWorkcenter from './AddWorkcenter.vue'
import EditWorkcenter from './EditWorkcenter.vue'
import TableDefault from "@core/components/table-default/TableDefault.vue";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    VueGoodTable,
    BTooltip,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    ToastificationContent,
    BSidebar,
    BButton,
    AddWorkcenter,
    EditWorkcenter,
    Breadcrumb,
    TableDefault,
    BModal
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      boundaryDrop: null,
      pageLength: 10,
      dir: false,
      currentWorkcenterId: '',
      fields: [
        {
          label: 'IntegrationPage.Actions',
          key: 'action',
          sortable: false
        },
        {
          label: 'WorkcenterList.Name',
          key: 'name',
          sortable: true
        },
        {
          label: 'WorkcenterList.Resources',
          key: 'resources',
          sortable: true,
        },
        {
          label: 'WorkcenterList.Users',
          key: 'users',
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: '',
      nameWorkcenters: [],
    }
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    setCurrentWorkcenter(id) {
      this.currentWorkcenterId = id
    },
    getWorkcenters() {
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/workcenter?site_guid=${this.currentSite.guid}`)
        .then(res => {
          this.rows = res.data.map(workcenter => {return {id: workcenter.id, name:workcenter.name ,resources: workcenter.resources.map(resource => resource.name).join(', '), users: workcenter.users.map(user => `${user.first_name} ${user.last_name}`).join(', ')}});
          this.nameWorkcenters = res.data.map(item => item.name);
          this.UPDATE_FLAG_SHOW_OVERLAY(false);
        })
    },
    closeSideBarAdd() {
      this.$bvModal.hide('modal-add-workcenter');
    },
    closeSideBarEdit() {
      this.$bvModal.hide('modal-edit-workcenter');
    },
    deleteWorkcenter(id) {
      this.$swal({
        title: this.$t('IntegrationPage.attention'),
        text: this.$t('WorkcenterList.DeleteAlert'),
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: this.$t('MySites.cancel'),
        confirmButtonText: this.$t('WorkcenterList.Delete'),
        customClass: {
          container: 'swal-conection',
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.UPDATE_FLAG_SHOW_OVERLAY(true);
          this.$http.delete(`/api/${this.$router.currentRoute.params.prefix}/workcenter/${id}?site_guid=${this.currentSite.guid}`)
            .then(() => {
              this.getWorkcenters()
              this.showToast(this.$t('WorkcenterList.Success'), 'SmileIcon', this.$t('WorkcenterList.DeletedText'), 'success')
            })
            .catch((error) => {
              this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
            })
        }
      })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
  },
  computed: {
    ...mapGetters('app', [
      'flagShowOverlay'
    ]),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getWorkcenters()
  },
  mounted() {
    this.boundaryDrop = document.querySelector('.app-content');
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.tooltip-workcenter {
  //não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0;
  .tooltip-inner {
      padding: 0;
      max-width: 100%;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;

      .info-tooltip-workcenter {
          max-width: 493px;
          padding: 7px 8px;
          text-align: left;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #fff;
          letter-spacing: 0.16px;
          border: 1px solid transparent;
      }
  }

  .arrow {
      &::before {
          border-top-color: #323232;
          border-bottom-color: #323232;
      }
  }
}

.overlay-mensage{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width:480px) {
  .info-tooltip-workcenter {
    max-width: 288px !important;
  }

  #card-workcenters {
    .c-header{
      .btn-new-workcenter {
        width: 100%;
        margin-top: 16px;
      }
    }

    .table-workcenter {
      th:nth-child(3),
      td:nth-child(3),
      th:nth-child(4),
      td:nth-child(4) {
        min-width: 277.5px !important;
      }
    }
  }

  .tooltip  {
    .tooltip-inner  {
      max-width: 288px !important;
    }
  }
}

#card-workcenters {
  overflow: hidden;
  .c-header {
    .c-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color:#4C4541 !important;
    }

    .btn-new-workcenter {
      border-radius: 5px !important;
      border: none;
      background: #974900 !important;
      border-color: #974900 !important;
      padding: 4px 14px;
      span{
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.0125em;
      }
      &:focus {
        background: #974900 !important;
        border-color: #974900 !important;
      }
    }
  }
}

.swal2-close {
  color: #4C4541 !important;
  font-size: 2em;
}

.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}

.table-workcenter thead th {
  text-transform: uppercase !important;
}

.table-workcenter {
  .add-ellipsis-w {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th {
    padding: 11px 9px !important;
  }

  thead th {
    padding: 10.5px 9px !important;
  }
  th:nth-child(1) {
    padding: 10.5px 7.65px !important;
  }

  td {
    padding: 14.5px 10px !important;
    height: 38px !important;
    letter-spacing: 0;
    border: 1px solid #E0E0E0 !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  td:nth-child(1) {
    text-align: center !important;
  }
  th:nth-child(1),
  td:nth-child(1) {
    min-width: 57px !important;
  }

  th:nth-child(2),
  td:nth-child(2) {
    min-width: 96px !important;
    max-width: 96px !important;
  }


  th:nth-child(3),
  td:nth-child(3) {
    min-width: 277.5px !important;
    max-width: calc(40vw - ((96px + 57px) / 2 ) - (2 * 16.8px) + 7px) !important;
    width: calc(40vw - ((96px + 57px) / 2 ) - (2 * 16.8px) + 7px)  !important;
  }

  th:nth-child(4),
  td:nth-child(4){
    min-width: 277.5px !important;
    max-width: calc(40vw - ((96px + 57px) / 2 ) - (2 * 16.8px) + 7px)  !important;
    width: calc(40vw - ((96px + 57px) / 2 ) - (2 * 16.8px) + 7px)  !important;
  }

  .d-action-workcenters {
    button {
      width: 32px;
      height: 28px;
      padding: 0;
      svg circle {
        color:#974900 !important;
      }
    }

    .dropdown-menu.show {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
    .dropdown-item {
      padding: 12px 21px 12px 21px !important;
      display: flex;

      &:hover {
        background-color: #FBEEE8;
        svg path{
          fill:#974900 !important;
        }
        span{
          color:#974900 !important;
        }
      }
      &.disabled {
        svg {
          path {
            fill: #CFC4BE !important;
          }
        }
        .text-action-pipeline {
          color: #CFC4BE !important;
        }
      }
      span {
        margin-left: 9px;
      }
    }
    button:hover {
      background-color: #FFDBC4!important;
    }
    svg{
      margin: 0 !important;
    }
  }
}

.dropdown-icon {
  vertical-align: top;
  margin-right: 5px;
}

.drop-workcenter a {
  color: #998F8A;
}

.drop-workcenter .dropdown-item:hover {
  color: #974900 !important;
}

.drop-workcenter .dropdown-item:focus {
  background-color: #ffdbc4 !important;
}

.drop-workcenter .dropdown-item:active {
  background-color: #ffdbc4 !important;
}

.swal-conection {
  .swal2-modal {
    padding: 0 !important;
    border-radius: 6px;
    width: 710px;

    .swal2-header {
      background-color: #D32F2F;
      padding: 20px 16px;
      border-radius: 6px 6px 0px 0px;
      flex-direction: row;
      justify-content: left;

      .swal2-title {
        color: #ffff;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin: 0;
      }

      .swal2-close {
        border-radius: 6px;
        width: 34px;
        background-color: #ffff;
        height: 34px;
        top: -5px;
        position: absolute;
        right: -5px;
      }
    }

    .swal2-content {
      padding: 20px 16px;

      .swal2-html-container {
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        line-height: 20px;
        color: #4C4541;
        display: flex !important;

      }
    }

    .swal2-actions {
      padding: 0 16px 20px 16px;
      display: flex;
      justify-content: right;

      .swal2-confirm {
        margin-left: 10px !important;
        order: 2;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        color: #ffff;
        border-color: #974900 !important;
        background-color: #974900 !important;
        border-radius: 5px;

        &:hover {
          box-shadow: none !important;
        }
      }

      .swal2-cancel {
        order: 1;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        color: #974900 !important;
        border-radius: 5px;
        border-color: transparent !important;

        &:hover {
          background-color: #ffede1 !important;
        }
      }
    }
  }
}

.modal-body {
  padding: 0;

  .modal-bottom {
    padding-bottom: 0 !important;
  }
}

.modal-content {
  .modal-header {
    padding: 20px 16px;

    .modal-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #4C4541;
    }

    .close {
      transform: none !important;
      transition: none !important;
      top: -12px;
      left: -5px;
    }
  }

  .add-row {
    svg {
      position: absolute;
    }

    .btn {
      background: #974900 !important;
      border-radius: 5px;
      border: none;
      width: 10px;
      display: flex;
      justify-content: center;
      height: 36px;
    }
  }

  .remove-row {
    svg {
      position: absolute;
    }

    .btn {
      background: #D32F2F !important;
      border-radius: 5px;
      border: none;
      width: 10px;
      display: flex;
      justify-content: center;
      height: 36px;
    }
  }

  .save-button {
    .btn {
      background-color: #974900 !important;
      border-radius: 5px;
      border: none;
      font-size: 17px;
      width: 100px;
      height: 45px;
    }
  }
}

.modal-footer {
  display: none;
}

.tooltip  {
  .tooltip-inner  {
    max-width: 493px;
    .info-tooltip-w {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>

